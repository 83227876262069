<template>
  <div class="page-wrapper">
    <HeaderBlock></HeaderBlock>
    <div class="content-scroll">
      <router-view></router-view>
      <FooterBlock></FooterBlock>
    </div>
  </div>
</template>

<script>
  import HeaderBlock from '@/components/blocks/Header';
  import FooterBlock from '@/components/blocks/Footer';
  export default {
    name: 'BaseTemplate',
    components: {
      'HeaderBlock': HeaderBlock,
      'FooterBlock': FooterBlock
    },
    data () {
      return {

      }
    }
  }
</script>

<style scoped>

</style>
